import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import style from './Chip.module.scss';

function Chip() {
    return (
        <div className={style.wrapper}>
            <Header />
            <main className={style.main}>
                <div className={style.container}>
                    <h2>Чипы Samsung 301b и 281b+PRO</h2>
                    <div className={style.text}>
                        <p>Два представленные разновидности светодиодов, выпущенных под брендом <span>«Самсунг»</span>, обладают высокой производительностью. Самым мощным считают <span>301b</span> со световой отдачей <span>220 лм/вт</span>. Однако такой показатель возможен только при некоторых параметрах. Отличительная черта технологии в том, что, например, при <span>5000 К</span> световая отдача будет выше, чем при <span>3000 К</span>.</p>
                        <p> Серии и <span>281b</span> уступают фавориту <span>301b</span> всего лишь на несколько сотых, однако цена у них ниже. Стоит при этом помнить, что световая отдача может заметно варьироваться. Так, при определённом сочетании бинов у <span>281b</span> с меньшими токами можно достичь даже более высокого значения лм/вт, чем у <span>301b</span> но придется использовать большее количество диодов.</p>
                        <p> Как итог, пользователь может сэкономить, ничего не теряя. Общие параметры светового потока будут такими же, как у популярного <span>301b</span>, но переплачивать не придётся. С точки зрения технологий, <span>Samsung</span> — один из лучших производителей светодиодов и кристаллов. Ключевое преимущество компании в том, что в ней работают квалифицированные, опытные инженеры.</p>
                        <p> Таким образом, перечисленные разновидности различаются в основном дизайном. Хотя <span>301b</span> — бесспорный фаворит, поэтому обходится дороже, такие же показатели (свыше двухсот люменов) можно получить на двух других моделях, хотя они и пользуются меньшим спросом. Убедиться в этом можно, просто сравнив результаты проведённых тестов. Разница стоимости может быть в пределах <span>30 %</span>, в то время как различия светового потока не превышают обычно одной десятой.</p>
                    </div>
                </div>
            </main>
            <Footer />
        </div >
    )
}

export default Chip;