import { Link } from 'react-router-dom';
import style from './Item.module.scss';

function Item(props) {
    const item = props.item;
    return (
        <div className={style.item} >
            <div className={style.wrapper} style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + `/image/${item.img}`})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover'
            }}>
                <div className={style.text}>
                    <h5 className={style.title}>{item.name}</h5>
                    <div className={style.panels}>
                        {/* <div className={`${style.panel} ${style.position}`}>{item.temp} &deg;</div> */}
                        <div className={style.panel}>{item.power} Вт.</div>
                        {/* <div className={style.panel}>{item.size} см</div> */}
                    </div>
                </div>
            </div>
            <Link className={style.telegram} to={item.link}>Перейти в <img src='/image/svg/telegram-svgrepo-white.svg' className={style.telegram__svg} /></Link>
        </div >
    )
}

export default Item;