import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Shop from "./pages/Shop/Shop";
import Chip from "./pages/Chip/Chip";
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/chip" element={<Chip />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
