import style from './ShopPage.module.scss';
import Header from "../../components/Header/Header";
import Footer from '../../components/Footer/Footer';
import Item from '../../components/Item/Item';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Shop() {
    const goods = useSelector((state) => state.goods.goodsArray);
    console.log(goods);

    return (
        <div className={style.wrapper}>
            <Header />
            <main className={style.main}>
                <div className={style.container}>
                    <h2 className={style.caption}>Магазин - <span>Еще больше ламп в Telegram</span></h2>
                    <div className={style.items}>
                        {goods.map((item) => <Item key={item.id} item={item} />)}
                    </div>
                    <div className={style.additionally}>
                        <p>А также сопутствующие товары</p>
                        <Link className={style.telegram} to={'https://t.me/Fanatik66rus'}>Перейти в <img src='/image/svg/telegram-svgrepo-white.svg' className={style.telegram__svg} /></Link>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Shop;