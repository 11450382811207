import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    goodsArray: [{
        id: 0,
        name: 'Маленькая лампа',
        img: '0.webp',
        // temp: '25',
        power: '120/240',
        // size: '14×14',
        link: 'https://t.me/Fanatik66rus'
    }, {
        id: 1,
        name: 'Средняя лампа',
        img: '1.webp',
        power: '480',
        link: 'https://t.me/Fanatik66rus'
    }, {
        id: 2,
        name: 'Большая  лампа',
        img: '2.webp',
        power: '720',
        link: 'https://t.me/Fanatik66rus'
    }]
}

const gooodsSlice = createSlice({
    name: 'goods',
    initialState,
    reducers: {}
});

// export { } = gooodsSlice.actions;

export default gooodsSlice.reducer;