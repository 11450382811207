import style from './HomePage.module.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Link } from 'react-router-dom';

function Home() {

    return (
        <div>
            <Header />
            <main className={style.main}>
                <section className={style.greeting}>
                    <div className={style.conteiner}>
                        <div className={style.body}>
                            <div className={style.text}>
                                <p className={style.location}><img src='/image/svg/location-pin-svgrepo-com.svg' className={style.location__svg} />Екатеренбург</p>
                                <h2 className={style.title}>Продажа ламп полного спектра для растений</h2>
                                <p className={style.subtitle}>Наш ассортимент включает в себя только высококачественные лампы с полным спектром освещения, которые идеально подходят для обеспечения растений всем необходимым для здорового роста и развития.</p>
                                <div className={style.confidentiality}><img src='/image/svg/lock-alt-svgrepo-white.svg' /><span>Сайт сохраняет конфиденциальность пользователя</span></div>
                            </div>
                            <img src='/image/light.webp' alt='Фото лампы' className={style.img__lapm} />
                        </div>
                    </div>
                </section>
                <section className={style.scroll}>
                    <div className={style.wrapper}>
                        <div className={style.text}>
                            <span>Безопасно</span><span>Безопасно</span><span>Безопасно</span><span>Безопасно</span><span>Безопасно</span><span>Безопасно</span><span>Безопасно</span>
                        </div>
                    </div>
                </section>
                <section className={style.advertisement}>
                    <div className={style.conteiner}>
                        <p><img src='/image/svg/sun-fill-svgrepo-com.svg' /><h3>Наши ламп полного спектра</h3> - за счет полного спектра, обеспечивается необходимое освещение для фотосинтеза и процессов роста растений, что способствует улучшению качества и урожайности, а также повышают иммунитет и защиту растений от болезней. Именно наши лампы экономят энергию и долговечны в использовании.</p>
                    </div>
                </section>
                <section className={style.chip}>
                    <div className={style.container}>
                        <div className={style.body}>
                            <div><img src='/image/svg/chip.svg' /><span>Наши лампы используют современные чипы: 301b</span> и <span>281b+PRO</span></div>
                            <Link className={style.btn} to={'/chip'}>Подробнее о чипах<img className={style.btn__svg} src='./image/svg/arrow-button.svg' /></Link >
                        </div>
                    </div>
                </section>
                <section className={style.carts}>
                    <div className={style.conteiner}>
                        <p className={style.caption}>Преимущества наших ламп</p>
                        <div className={style.body}>
                            <div className={style.cart}>
                                <h4 className={style.title}>Улучшенный рост растений</h4>
                                <p className={style.subtitle}>Полный спектр способствует лучшему поглощению света, что важно для процесса фотосинтеза.</p>
                            </div>
                            <div className={style.cart}>
                                <p className={style.title}>Качество и долговечность</p>
                                <p className={style.subtitle}>Наши лампы изготовлены из высококачественных материалов, которые выдерживают любые погодные условия.</p>
                            </div>
                            <div className={style.cart}>
                                <p className={style.title}>Экологичность</p>
                                <p className={style.subtitle}>Мы используем только безопасные и экологически чистые материалы, которые не наносят вреда окружающей среде.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={style.safety}>
                    <div className={style.container}>
                        <div className={style.body}>
                            <h5 className={style.title}>Безопасность покупки</h5>
                            <div className={style.text}>
                                <p><img src='/image/svg/lock-alt-svgrepo-com.svg' /><span>Политика Конфиденциальности:</span> Мы полностью соблюдаем Общий регламент по защите данных (GDPR), что гарантирует вам контроль над вашей личной информацией.</p>
                                <p><img src='/image/svg/lock-alt-svgrepo-com.svg' /><span>Защита Личных Данных:</span> Мы используем технологии шифрования для защиты ваших личных данных и информации.</p>
                                <p><img src='/image/svg/lock-alt-svgrepo-com.svg' /><span>Поддержка 24/7:</span> Наша команда поддержки всегда на связи, чтобы помочь вам с любыми вопросами или проблемами.</p>
                                <p><img src='/image/svg/lock-alt-svgrepo-com.svg' /><span>Удобство Оплаты:</span> Мы предлагаем различные методы оплаты, включая кредитные карты, электронные кошельки и банковские переводы.</p>
                                <p><img src='/image/svg/lock-alt-svgrepo-com.svg' /><span>Отзывы Покупателей:</span> Наши страницы товаров содержат реальные отзывы клиентов, чтобы вы могли сделать обоснованный выбор.</p>
                                <p><img src='/image/svg/lock-alt-svgrepo-com.svg' /><span>Быстрая Доставка:</span> Мы сотрудничаем с надежными службами доставки для оперативной и аккуратной доставки вашего заказа.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className={style.order}>
                    <div className={style.container}>
                        <div className={style.body}>
                            <p className={style.title}>Заказать просто</p>
                            <p className={style.text}>Для оформления товара нужно перейти по ссылке в <span>telegram</span>, и мы доставим вашу покупку прямо к вашему порогу. Сделай заказ уже сегодня.</p>
                        </div>
                    </div>
                </section>
                <section className={style.button}>
                    <div className={style.container}>
                        <Link to='https://t.me/Fanatik66rus'><img className={style.bg} src='/image/plants.webp' />
                            <button>Перейти в <img src='/image/svg/telegram-svgrepo-com.svg' className={style.telegram__svg} /></button>
                        </Link>
                    </div>
                </section>
            </main>
            <Footer />
        </div >
    )
}

export default Home;